<div class="d-block">
	<div style="width: 400px" class="mx-auto">
		<img class="w-100" src="assets/img/billySays.jpg">
		<div class="mx-auto bubble">
			<h2>
				"Sorry, Ain't got this part done yet."
			</h2>
		</div>
	</div>
</div>
