<div class="d-block">
	<div style="width: 400px" class="mx-auto">
		<img class="w-100" src="assets/img/billySays.svg">
		<div class="mx-auto bubble">
			<h2>
				"This part ain't done yet."
			</h2>
		</div>
	</div>
</div>
