<div class="d-block">
<div class="mx-auto card-deck">
  <div class="card text-center border-primary mb-3" style="max-width: 18rem;">
    <div style="height:400px;">
      <img class="card-img-top" src="assets/img/ssvd.png" alt="SSVD image">
    </div>
    <div class="card-body">
      <h5>Smart Sensor / Valve Decoder</h5>
      <p class="card-text">Combination ground moisture sensor and 2Wire valve
    decoder.</p>
    </div>
  </div>
  <div class="card text-center border-primary mb-3" style="max-width: 18rem;">
    <div style="height:400px;">
      <img class="card-img-top" src="assets/img/cntl.png" alt="smart controller image">
    </div>
    <div class="card-body">
      <h5>Smart Controller</h5>
      <p class="card-text">Wifi/Bluetooth contoller base unit</p>
    </div>
  </div>
  <div class="card text-center border-primary mb-3" style="max-width: 18rem;">
    <div style="height:400px;">
      <img style="height=500px" class="card-img-top" src="assets/img/vd3.png" alt="SSVD image">
    </div>
    <div class="card-body">
      <h5>3-Valve Decoder</h5>
      <p class="card-text">2Wire valve decoder for up to 3 valves.</p>
    </div>
  </div>
</div>
</div>
