
<nav class="navbar navbar-expand-md navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="">
      <img src="assets/img/logo.png" alt="" width=48 height=48>
    </a>
    <button class="navbar-toggler" type="button"
    (click)="isCollapsed=!isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navMenu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navMenu" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="navbar-item btn" routerLink="/products">Products</a>
        </li>
        <li class="nav-item">
          <a class="navbar-item btn" routerLink="/design">Designer</a>
        </li>
        <li class="nav-item">
          <a class="navbar-item btn" routerLink="/design">Controller</a>
        </li>
      </ul>
      <form class="d-flex ml-auto">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
